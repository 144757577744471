import * as moment from 'moment';

export class DateUtil {
  public static compareFormField(a: any, b: any): number {
    return this.compareDates(this.anyToDate(a), this.anyToDate(b));
  }

  public static compareDates(a: Date, b: Date): number {
    if (!a && !b) {
      return 0;
    }
    if (!a && b) {
      return 1;
    }
    if (a && !b) {
      return -1;
    }
    return a.getTime() - b.getTime();
  }

  public static isEqual(a: any, b: any) {
    const dateA = this.anyToDate(a);
    const dateB = this.anyToDate(b);

    return dateA.getTime() === dateB.getTime();
  }

  public static daysBetween(a: Date, b: Date): number {
    if (this.isDate(a) && this.isDate(b)) {
      return moment(a).diff(moment(b), 'days');
    }
    return 0;
  }

  public static formField(date: Date): string {
    if (date === null || date === undefined) {
      return undefined;
    }
    date = this.toDateObj(date);
    return (
      date.getFullYear() +
      '-' +
      this.getMonthStr(date) +
      '-' +
      this.getDaysStr(date)
    );
  }

  public static isDate(value: any): boolean {
    const type = typeof value;
    if (type !== 'number' && type !== 'string' && !(value instanceof Date)) {
      return false;
    }
    const date = new Date(value);
    return date && !isNaN(date.getFullYear());
  }

  public static ddmmyyyy(date: Date): string {
    if (date) {
      date = this.toDateObj(date);
      return (
        this.getDaysStr(date) +
        '.' +
        this.getMonthStr(date) +
        '.' +
        date.getFullYear()
      );
    }
    return '';
  }

  public static ddmmyyyyForChris(date: Date): string {
    if (date) {
      date = this.toDateObj(date);
      return (
        this.getDaysStr(date) +
        '/' +
        this.getMonthStr(date) +
        '/' +
        date.getFullYear()
      );
    }
    return '';
  }

  public static ddmmyyyyHHMM(date: Date): string {
    if (date) {
      date = this.toDateObj(date);
      return (
        this.getDaysStr(date) +
        '.' +
        this.getMonthStr(date) +
        '.' +
        date.getFullYear() +
        ' ' +
        this.padNum(date.getHours()) +
        ':' +
        this.padNum(date.getMinutes())
      );
    }
    return '';
  }

  public static ddmmyyyyHHMM_TZ(date: Date): string {
    if (date) {
      return this.ddmmyyyyHHMM(date) + ' (UTC)';
    }
    return '';
  }

  public static fileNameTimeStamp(date: Date): string {
    if (date) {
      date = this.toDateObj(date);
      const year = date.getUTCFullYear();
      const month = this.padNum(date.getUTCMonth() + 1);
      const day = this.padNum(date.getUTCDate());
      const hour = this.padNum(date.getUTCHours());
      const minutes = this.padNum(date.getUTCMinutes());
      return `${year}${month}${day}_${hour}${minutes}`;
    }
    return '';
  }

  public static anyToDate(date: any): Date {
    if (!date) {
      return null;
    }
    if (typeof date === 'string') {
      const lenNoTime = 'yyyy-mm-dd'.length;
      const lenTime = lenNoTime + ':HH-MM-SS'.length;
      const lenTimeAndZone = lenTime + ' (UTC)'.length;
      if (date.length === lenNoTime) {
        // either yyyymmdd or ddmmyyyy
        const idxDot = date.indexOf('.');
        const idxDash = date.indexOf('-');
        if (
          (idxDot === 4 && idxDash === -1) ||
          (idxDot === -1 && idxDash === 4)
        ) {
          // yyyymmdd
          return this.yyyymmddToDate(date);
        } else if (
          (idxDot === 2 && idxDash === -1) ||
          (idxDot === -1 && idxDash === 2)
        ) {
          // ddmmyyyy
          return this.ddmmyyyyToDate(date);
        }
      } else if (date.length === lenTime) {
        // either yyyymmdd_HHMMSS or ddmmyyyy_HHMMSS
        const idxDot = date.indexOf('.');
        const idxDash = date.indexOf('-');
        if (
          (idxDot === 4 && idxDash === -1) ||
          (idxDot === -1 && idxDash === 4)
        ) {
          return this.yyyymmddhhmmssToDate(date);
        } else if (
          (idxDot === 2 && idxDash === -1) ||
          (idxDot === -1 && idxDash === 2)
        ) {
          return this.ddmmyyyyhhmmssToDate(date);
        }
      } else if (date.length === lenTimeAndZone) {
        // either yyyymmdd_HHMM_UTC or ddmmyyyy_HHMM_UTC
        // TODO: needs to be implemented
        console.error(
          'DateFormatService.anyToDate(' + date + ') - NOT IMPLEMENTED'
        );
      }
      return new Date(date);
    }
    if (typeof date === 'number') {
      const dateByNumber = new Date(date);
      if (!isNaN(dateByNumber.getFullYear())) {
        return dateByNumber;
      }
      return null;
    }
    const newDate = new Date(date);
    if (newDate && newDate.getFullYear && !isNaN(newDate.getFullYear())) {
      return newDate;
    }
    return null;
  }

  public static ddmmyyyyToDate(date: string): Date {
    const day = Number(date.substring(0, 2));
    const month = Number(date.substring(3, 5)) - 1;
    const year = Number(date.substring(6, 10));
    return new Date(Date.UTC(year, month, day));
  }

  public static yyyymmddToDate(date: string): Date {
    const day = Number(date.substring(8, 10));
    const month = Number(date.substring(5, 7)) - 1;
    const year = Number(date.substring(0, 4));
    return new Date(Date.UTC(year, month, day));
  }

  public static ddmmyyyyhhmmssToDate(date: string): Date {
    const day = Number(date.substring(0, 2));
    const month = Number(date.substring(3, 5)) - 1;
    const year = Number(date.substring(6, 10));
    const hour = Number(date.substring(11, 13));
    const minute = Number(date.substring(14, 16));
    const second = Number(date.substring(7));
    return new Date(Date.UTC(year, month, day, hour, minute, second));
  }

  public static yyyymmddhhmmssToDate(date: string): Date {
    const day = Number(date.substring(8, 10));
    const month = Number(date.substring(5, 7)) - 1;
    const year = Number(date.substring(0, 4));
    const hour = Number(date.substring(11, 13));
    const minute = Number(date.substring(14, 16));
    const second = Number(date.substring(17));
    return new Date(Date.UTC(year, month, day, hour, minute, second));
  }

  public static toDate(date: Date): Date {
    if (!date) {
      return null;
    }
    return this.toDateObj(date);
  }

  private static toDateObj(date: Date): Date {
    if (!date.getFullYear || !date.getMonth || !date.getDate) {
      return new Date(date);
    }
    return date;
  }

  private static getDaysStr(date: Date): string {
    return this.padNum(date.getDate());
  }

  private static getMonthStr(date: Date): string {
    return this.padNum(date.getMonth() + 1);
  }

  private static padNum(value: number): string {
    if (value < 10) {
      return '0' + value;
    }
    return '' + value;
  }

  private static padToNDigits(num: number, targetLength: number = 2): string {
    return num.toString().padStart(targetLength, '0');
  }

}
