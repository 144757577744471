import { revid, version } from './version';

export const environment = {
  production: false,
  name: 'test',
  title: 'DigiRES test',
  showMap: true,

  version: version,
  revid: revid,
  apiURL: '/api/*',
  udpURL: 'https://udp-test.ext.np.renewables-apps.com/',
};
