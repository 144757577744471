import { Component, OnInit } from '@angular/core';
import {AbstractInputDirective} from '../AbstractInputDirective';

@Component({
  selector: 'digires-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent extends AbstractInputDirective {
}
